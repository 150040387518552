// React core imports
import React from 'react';
import ReactDOM from 'react-dom/client';

// Import main App component and styles
import App from './App';

// Import Amplify UI styles for authentication components
import '@aws-amplify/ui-react/styles.css';

// AWS Amplify configuration
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports'; // Ensure this path correctly points to the aws-exports file
Amplify.configure(awsExports);

// Create a root container where the React app will be rendered
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component within React's StrictMode for highlighting potential problems
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
