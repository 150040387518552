import React from 'react';
import './App.css'; // Import the CSS for styling
import HomePageBackground from './images/HomePage.jpg'; // Import your background image

function App() {
  // Define inline styles for the background
  const backgroundStyle = {
    backgroundImage: `url(${HomePageBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white', // Set text color
  };

  const firstTitleStyle = {
    marginTop: '25px',
  };

  return (
    <div style={backgroundStyle}>
      {/* Text elements centered on the background */}
        <h1 style={firstTitleStyle} className="title">#ForwardFartherFaster</h1>
        <h1 className="title">To A Better Future For All</h1>
        <p className="description">
          Capturing humanity's untold tales, emotions, and ideas
        </p>
        <p className="description">
          through anonymous submissions, empowered by AI insights.
        </p>
        <p />
        <p />
        <p />
        <p className="title">COMING SOON!</p>
    </div>
  );
}

export default App;
